import Client from '@/services/Client'
const axios = new Client()
import Cookies from 'js-cookie'

export const grade = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    createColor({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('grade/color', data)
          .then(res => {
            
            if(res.data) {
              resolve(res)
            } else reject(res)

          })
          .catch(err => reject(err))
      })
    },
    createGrade({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('grade/grade', data)
          .then(res => {
            
            if(res.data) {
              resolve(res)
            } else reject(res)

          })
          .catch(err => reject(err))
      })
    },
  }
}
