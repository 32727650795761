<template>
     <div class="tooltip-container" @mouseover="show"  @mouseleave="hide">
        <div class="_tooltip mt-1" v-if="showTooltip">
            {{ text }}
        </div>
        <slot  @mouseover="show"></slot>
    </div>
</template>

<script>
export default {
    name: "base-tooltipe",
    props: ['active', 'text'],
    data(){
        return {
            showTooltip: false
        }
    },
    methods: {
        show(){
            if(this.active){
                this.showTooltip = true
            }
        },
        hide(){
            this.showTooltip = false
        },

    }
}
</script>


<style>
.tooltip-container {
    position: relative;
}

._tooltip {
    position: absolute;
    z-index: 9999;
    /* display: none; */
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 0.25rem;
    top: -26px;
    pointer-events: none;
    white-space: nowrap;
}

.tooltip-container:hover ._tooltip {
    display: block;
}
</style>