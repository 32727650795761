var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('notifications'),(_vm.isMobile)?_c('div',[(
        _vm.getUserLogged.company && _vm.getUserLogged.company.company_plan.status === 'TESTING'
      )?_c('b-alert',{staticClass:"m-0 text-center",staticStyle:{"font-size":"18px"},attrs:{"show":"","dismissible":""}},[_vm._v(" Bem vindo a Liga ERP! Seu período de avaliação termina em "+_vm._s(_vm.daysCompanyToTest)+" dias "),_c('base-button',{attrs:{"outline":"","size":"sm"},on:{"click":() => _vm.$router.push('/faturas-assinaturas')}},[_vm._v("ver planos")])],1):_vm._e(),(
        _vm.getUserLogged.company &&
        _vm.getUserLogged.company.company_plan.status === 'CANCELED'
      )?_c('b-alert',{staticClass:"m-0 text-center",staticStyle:{"font-size":"18px"},attrs:{"show":"","variant":"warning"}},[_vm._v(" Pagamento não registrado. Sua assinatura encontra-se com o status de cancelamento. "),_c('base-button',{attrs:{"outline":"","size":"sm"},on:{"click":() => _vm.$router.push('/faturas-assinaturas')}},[_vm._v("Regularizar")])],1):_vm._e()],1):_vm._e(),_c('side-bar',{staticClass:"sub-menu-links default-bg-menu",class:{ 'loading-skeleton': _vm.getUserLoggedRoles.length == 0 },staticStyle:{"z-index":"1 !important"}},[_c('template',{slot:"links"},[_c('div',{style:(!_vm.isMobile ? 'overflow-y: scroll; height: 60vh' : '')},[_c('sidebar-item',{attrs:{"active":_vm.isCurrentRoute('Dashboard'),"link":{
            name: 'Dashboard',
            path: '/dashboard',
            icon: 'ni ni-tv-2 ',
          }}}),(_vm.permission('sale.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Sale'),"link":{
            name: 'Novo pedido',
            path: '/balcao/vendas',
            icon: 'fa fa-cash-register',
          }}}):_vm._e(),_c('a',{staticClass:"content-sub-menu-order ml-custom-itens pl-1 d-flex justify-content-between nav-link",staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":_vm.showSubMenuOrder}},[_c('span',{staticClass:"text-white"},[_c('i',{staticClass:"text-white",class:_vm.iconMenuOrder}),_c('span',{staticClass:"nav-link-text"},[_vm._v(_vm._s(_vm.labelMenuOrder))])]),_c('i',{staticClass:"mt-1 text-white",class:{
              'ni ni-bold-right': !_vm.isMobile,
              'ni ni-bold-down': _vm.isMobile,
              'mr-5': _vm.isMobile,
            },staticStyle:{"font-size":"13px"}})]),_c('div',{staticClass:"sub-menu-order d-none sub-menu-links default-bg-menu",class:{ 'ml-4': _vm.isMobile },on:{"mouseleave":_vm.showSubMenuOrder}},[(_vm.permission('order.read'))?_c('sidebar-item',{style:(_vm.activeMenuProduct(_vm.$route.path)),attrs:{"activeClass":_vm.isCurrentRoute('Order') || _vm.isCurrentRoute('sale-create'),"link":{
              name: 'Pedidos',
              path: '/pedidos',
              icon: 'ni ni-cart ',
            }}}):_vm._e(),(_vm.permission('sale.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Sale'),"link":{
              name: 'Vendas',
              path: '/vendas',
              icon: 'ni ni-bag-17',
            }}}):_vm._e(),(_vm.permission('coleta.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('coleta'),"link":{ name: 'Coletas', path: '/coletas', icon: 'ni ni-square-pin ' }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
              name: 'Serviços',
              path: '/producoes',
              icon: 'ni ni-settings',
            }}})],1),(_vm.permission('budget.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('budget-read'),"link":{
            name: 'Orçamentos',
            path: '/orcamentos',
            icon: 'ni ni-single-copy-04 ',
          }}}):_vm._e(),(_vm.permission('movements.read'))?_c('a',{staticClass:"content-sub-menu-order ml-custom-itens pl-1 d-flex justify-content-between nav-link",staticStyle:{"cursor":"pointer"},attrs:{"href":"#"},on:{"click":_vm.showSubMenuMovement}},[_c('span',[_c('i',{staticClass:"text-white",class:_vm.iconMenuMovement}),_c('span',{staticClass:"nav-link-text text-white"},[_vm._v(_vm._s(_vm.labelMenuMovement))])]),_c('i',{staticClass:"mt-1 text-white",class:{
              'ni ni-bold-right': !_vm.isMobile,
              'ni ni-bold-down': _vm.isMobile,
              'mr-5': _vm.isMobile,
            },staticStyle:{"font-size":"13px","color":"rgb(184, 184, 184)"}})]):_vm._e(),_c('div',{staticClass:"sub-menu-movement d-none sub-menu-links default-bg-menu",class:{ 'ml-4': _vm.isMobile },on:{"mouseleave":_vm.showSubMenuMovement}},[(_vm.permission('movementsreport.read'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Relatório de Movimentações',
              path: '/relatorio/movimentacoes',
              icon: 'ni ni-chart-pie-35',
            }}}):_vm._e(),(_vm.permission('movements.read'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Movimentações',
              path: '/movimentacoes',
              icon: 'ni ni-sound-wave ',
            }}}):_vm._e(),(_vm.permission('movements.read'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Contas a pagar',
              path: '/contas-a-pagar',
              icon: 'ni ni-credit-card',
            }}}):_vm._e(),(_vm.permission('movements.read'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Contas a receber',
              path: '/contas-a-receber',
              icon: 'ni ni-money-coins',
            }}}):_vm._e(),(_vm.permission('movement.dre'))?_c('sidebar-item',{attrs:{"link":{
              name: 'Plano de contas',
              path: '/plano-de-contas',
              icon: 'ni ni-vector',
            }}}):_vm._e()],1),(_vm.permission('customer.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Customer') || _vm.isCurrentRoute('Customer-create'),"link":{
            name: 'Clientes',
            path: '/clientes',
            icon: 'ni ni-favourite-28',
          }}}):_vm._e(),(_vm.permission('product.read'))?_c('a',{staticClass:"content-sub-menu-product ml-custom-itens pl-1 d-flex justify-content-between text-white nav-link",attrs:{"href":"#"},on:{"click":_vm.showSubMenuProduct}},[_c('span',[_c('i',{class:_vm.iconMenuProduct}),_c('span',{staticClass:"nav-link-text text-white"},[_vm._v(_vm._s(_vm.labelMenuProduct))])]),_c('i',{staticClass:"mt-1",class:{
              'ni ni-bold-right': !_vm.isMobile,
              'ni ni-bold-down': _vm.isMobile,
              'mr-5': _vm.isMobile,
            },staticStyle:{"font-size":"13px","color":"rgb(184, 184, 184)"}})]):_vm._e(),_c('div',{staticClass:"sub-menu-product d-none sub-menu-links bg-default",class:{ 'ml-4': _vm.isMobile },on:{"mouseleave":_vm.showSubMenuProduct}},[(_vm.permission('product.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('product-create'),"link":{ name: 'Produtos', path: '/produtos', icon: 'ni ni-box-2 ' }}}):_vm._e(),(_vm.permission('product.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('product-compras'),"link":{
              name: 'Minhas Compras',
              path: '/produtos/compras',
              icon: 'ni ni-basket ',
            }}}):_vm._e(),(_vm.permission('product.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('productEstoque-read'),"link":{
              name: 'Estoques',
              path: '/produtos/estoques',
              icon: 'ni ni-vector ',
            }}}):_vm._e(),(_vm.permission('product.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('productEntrada-read'),"link":{
              name: 'Movimentação de estoque',
              path: '/produtos/movimentacao',
              icon: 'ni ni-app ',
            }}}):_vm._e()],1),(_vm.permission('report.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Sale'),"link":{
            name: 'Relatorios',
            path: '/relatorios',
            icon: 'ni ni-chart-bar-32',
          }}}):_vm._e(),(
            _vm.permission('seller.read') ||
            _vm.permission('provider.read') ||
            _vm.permission('user.read')
          )?_c('a',{staticClass:"content-sub-menu-product ml-custom-itens pl-1 d-flex justify-content-between nav-link",attrs:{"href":"#"},on:{"click":_vm.showSubMenuVerMais}},[_c('span',{staticClass:"text-white"},[_c('i',{staticClass:"ni ni-bullet-list-67 text-white"}),_vm._v(" Ver mais ")]),_c('i',{staticClass:"mt-1 text-white",class:{
              'ni ni-bold-right': !_vm.isMobile,
              'ni ni-bold-down': _vm.isMobile,
              'mr-5': _vm.isMobile,
            },staticStyle:{"font-size":"13px","color":"rgb(184, 184, 184)"}})]):_vm._e(),_c('div',{staticClass:"sub-menu-ver-mais sub-menu-links d-none default-bg-menu",class:{ 'ml-4': _vm.isMobile },on:{"mouseleave":_vm.showSubMenuVerMais}},[(_vm.permission('frete.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('frete.read'),"link":{
              name: 'Meus envios',
              path: '/envios',
              icon: 'ni ni-delivery-fast ',
            }}}):_vm._e(),(_vm.permission('taxNote.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Sale'),"link":{
              name: 'Notas fiscais',
              path: '/notas',
              icon: 'ni ni-single-copy-04',
            }}}):_vm._e(),(_vm.permission('seller.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Seller'),"link":{
              name: 'Vendedores',
              path: '/vendedores',
              icon: 'ni ni-diamond',
            }}}):_vm._e(),(_vm.permission('provider.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('Provider') || _vm.isCurrentRoute('Provider-create'),"link":{
              name: 'Fornecedores',
              path: '/fornecedor',
              icon: 'ni ni-shop ',
            }}}):_vm._e(),(_vm.permission('user.read'))?_c('sidebar-item',{attrs:{"activeClass":_vm.isCurrentRoute('User') || _vm.isCurrentRoute('user-create'),"link":{
              name: 'Usuários',
              path: '/usuarios',
              icon: 'ni ni-single-02',
            }}}):_vm._e()],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"p-3 content-sub-menu w-100 default-bg-menu"},[_c('div',{staticClass:"w-100"},[(_vm.permission('subscription.read'))?_c('div',{staticClass:"mb-3"},[_c('sidebar-item',{staticStyle:{"font-size":"19px"},attrs:{"link":{
                name: 'Planos',
                path: '/marketplace',
                icon: 'ni ni-shop',
              }}})],1):_vm._e(),_c('div',[_c('b-dropdown-item',{staticStyle:{"cursor":"pointer","width":"100%"},on:{"click":function($event){return _vm.$store.dispatch('logoutUser')}}},[_c('i',{staticClass:"ni ni-user-run text-white"}),_c('span',{staticClass:"font-weight-400 description mb-0 text-white",staticStyle:{"font-size":"16px"}},[_vm._v("Sair")])])],1)])]):_vm._e()])],2),_c('div',{staticClass:"main-content"},[(!_vm.isMobile)?_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}):_vm._e(),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":300,"origin":"center top","mode":"out-in"}},[_c('div',{staticClass:"p-3"},[_c('router-view',{staticClass:"show-page"})],1)])],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }