import Client from '@/services/Client'
const axios = new Client()

export const pdv = {
  state: {
    productsPdv: [],
    customerPdv: {},
    fretePdv: {price: 0},
    paymentPdv: {
      transactions: [],
      withFiscalNote: true,
      invoice_cost: 0,
      note_amount: 0,
      due_date: new Date().toISOString().substring(0, 10),
      recurrency: false
    },
    finishedPdv: [],
    totalPdv: 0,
    zipcodePdv: null,
    pulseBtnGenerateAccount: false,
  },
  getters: {
    getProductsPdv: state => state.productsPdv,
    getPulseBtnGenerateAccount: state => state.pulseBtnGenerateAccount,
    getCustomerPdv: state => state.customerPdv,
    getFretePdv: state => state.fretePdv,
    getPaymentPdv: state => state.paymentPdv,
    getFinishedPdv: state => state.finishedPdv,
    getTotalPdv: state => state.totalPdv,
    getZipcodePdv: state => state.zipcodePdv,
    getTotalTransactions(state, getters) {
      return state.paymentPdv.transactions.reduce((value, item) => {
        let amount = item.amount
        
        if (typeof amount !== 'number'){
          amount = getters.replaceValue(item.amount)
        }

        return (value += parseFloat(amount));
      }, 0);
    },
    sumTotalPdv: (state, getters) => {
      let products = state.productsPdv.map((item) => Object.assign({}, item));
      return (
        products.reduce((total, obj) => {
          if (obj.dynamic_amount) {
            obj.amount = parseFloat(getters.replaceValue(obj.amount));
          }
          return total + obj.amount * obj.total_item - getters.replaceValue(obj.discount);
        }, 0) +
        ( state.fretePdv.price ) +
          parseFloat(getters.replaceValue(state.paymentPdv.invoice_cost))
      );
    },
    sumTotalProductsPdv: (state, getters) => {
      let products = state.productsPdv.map((item) => Object.assign({}, item));
      return (
        products.reduce((total, obj) => {
          if (obj.dynamic_amount) {
            obj.amount = parseFloat(getters.replaceValue(obj.amount));
          }
          return total + obj.amount * obj.total_item - getters.replaceValue(obj.discount);
        }, 0)
      );
    },
    checkProductFrete(_, getters) {
      let productChecked = {}
      getters.getProductsPdv.every(
        (product) => {
          if(product.total_item &&
            product.weight &&
            product.height &&
            product.width &&
            product.length)
          {
            return false
          }
          productChecked = product
          return true
        }
      );
      return productChecked
    },
    checkCustomerAddress(_, getters) {
      if (!getters.customerPdv) return false;
      return (
        getters.customerPdv &&
        getters.customerPdv.address &&
        getters.customerPdv.address.city &&
        getters.customerPdv.address.neighborhood &&
        getters.customerPdv.address.zipcode &&
        getters.customerPdv.address.street &&
        getters.customerPdv.address.number &&
        getters.customerPdv.address.uf
      );
    },
  },
  mutations: {
    setPulseBtnGenerateAccount: (state, value) => state.pulseBtnGenerateAccount = value,
    setProductsPdv: (state, value) => state.productsPdv = value,
    setCustomerPdv: (state, value) => {
      state.customerPdv = value
      state.zipcodePdv = value.address ? 
        value.address.zipcode : null
    },
    setFretePdv: (state, value) => state.fretePdv = value,
    setPaymentPdv: (state, value) => state.paymentPdv = value,
    setFinishedPdv: (state, value) => state.finishedPdv = value,
    setTotalPdv: (state, value) =>  state.totalPdv = value,
    setZipcodePdv: (state, value) =>  state.zipcodePdv = value,
  },
  actions: {
    findProductPDV({commit, getters}, search) {
      return new Promise((resolve, reject) => {
        axios.get('pdv/product'+getters.getQueryString(search))
          .then(response => {
            return resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    getCustomerPDV({commit, getters}, search) {
      return new Promise((resolve, reject) => {
        axios.get('pdv/customer'+getters.getQueryString(search))
          .then(response => {
            return resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    configProductsPdv({commit, getters}, product){
      getters.getProductsPdv.push(product)
      commit('setProductsPdv', getters.getProductsPdv)
      commit('setTotalPdv', getters.sumTotalPdv)
    },
    configureFretePdv({commit, getters}, frete){
      commit('setFretePdv',frete)
      commit('setTotalPdv', getters.sumTotalPdv)
    },
    configRemoveProductsPdv({commit, getters}, product){

      const products = getters.getProductsPdv.filter((item) => {
        if (item.id !== product.id) {
          return true; // Mantém o item na lista
        } else {
          // Se os IDs forem iguais, verifica se as características (cor e grade) também são iguais
          if (
            product.product_grade &&
            product.product_grade.length && // Se não há grade no produto a ser removido
            (item.grade.color !== product.grade.color ||
              item.grade.grade !== product.grade.grade)
          ) {
            return true; // Mantém o item na lista
          } else {
            return false; // Remove o item da lista
          }
        }
      })
      
      commit('setProductsPdv', products)
      commit('setTotalPdv', getters.sumTotalPdv)
    },
    configPaymentPdv({commit, getters}, payment){

      if(payment.restart){
        getters.getPaymentPdv.transactions = []
        console.log('getters.getPaymentPdv.transactions', getters.getPaymentPdv.transactions)
        payment.transactions.forEach(element => {
          getters.getPaymentPdv.transactions.push(element)
        });
      }else if(payment.transactions.length){
        payment.transactions.forEach(element => {
          getters.getPaymentPdv.transactions.push(element)
        });
      }else if(typeof getters.getPaymentPdv.transactions == 'object' && payment.transactions.amount){
          getters.getPaymentPdv.transactions.push(payment.transactions)
      }
      commit('setPaymentPdv', {
        due_date: payment.due_date,
        withFiscalNote: payment.withFiscalNote,
        invoice_cost: payment.invoice_cost,
        note_amount: payment.note_amount,
        recurrency: payment.recurrency,
        transactions: getters.getPaymentPdv.transactions
      })
    },
    resetPdv({commit}){

      commit('setProductsPdv', [])
      commit('setCustomerPdv', {})
      commit('setFretePdv',  {price: 0})
      commit('setPaymentPdv', {
        transactions: [],
        withFiscalNote: true,
        invoice_cost: 0,
        note_amount: 0,
        recurrency: false,
        due_date: new Date().toISOString().substring(0, 10),
      })
      commit('setFinishedPdv', [])
      commit('setTotalPdv', 0)
      commit('setZipcodePdv', null)

    }
    
    
  }
}
