import Client from '@/services/Client'
const axios = new Client()

export const pagarme = {
  state: {
  },
  getters: {
    getMotivosErros() {
      return {
        "0000":
          "Transação aprovada com sucesso",
        "0001":
          "Transação aprovada com identificação",
        "0002":
          "Transação aprovada com valor parcial",
        "0003":
          "Transação aprovada (VIP)",
        "0004":
          "Transação aprovada (Track 3)",
        "0005":
          "Transação aprovada Tipo da conta especificada pelo banco/emissor do cartão.",
        "0006":
          "Transação aprovada com valor parcial Tipo da conta especificada pelo banco/emissor do cartão",
        "0008":
          "Transação aprovada com disputa de taxas",
        "0009":
          "Transação aprovada com limite de crédito extra",
        "0010":
          "Transação aprovada Usuário reativado",
        "0011":
          "Transação aprovada Terminal desabilitado para processar online",
        "0012":
          "Transação aprovada e processada por um terminal offline",
        "0013":
          "Transação aprovada e processada offline após revisão",
        '1000':
          'Transação não autorizada, oriente o portador a contatar o banco/emissor do cartão',
        '1001':
          'Cartão vencido ou data de vencimento incorreta Oriente o usuário a contatar o banco/emissor do cartão',
        '1002':
          "Cartão com suspeita de fraude",
        '1003':
          "Estabelecimento, favor contatar a operadora de cartão",
        '1004':
          "Cartão com restrição Oriente o usuário a contatar o banco/ emissor do cartão",
        '1005':
          "Estabelecimento, favor contatar a operadora de cartão",
        '1006':
          "O portador do cartão excedeu o número de tentativas de senha",
        '1007':
          "Transação recusada pelo banco, oriente o portador a contatar o banco / emissor",
        '1008':
          "Transação recusada pelo banco, oriente o portador a contatar o banco / emissor",
        '1009':
          "Número de afiliação do estabelecimento comercial está inválido",
        '1010':
          "O valor solicitado para captura não é válido",
        '1011':
          "Número do cartão inválido Oriente o usuário a contatar o banco / emissor do cartão",
        '1012':
          "Senha necessária para efetuar a transação",
        '1013':
          "Taxa inválida",
        '1014':
          "Conta requerida não encontrada",
        '1015':
          "Função não suportada",
        '1016':
          "Saldo insuficiente",
        '1017':
          "Senha incorreta",
        '1018':
          "Não há registros deste cartão",
        '1019':
          "Sistema de prevenção do banco não autorizou a compra Oriente o usuário a contatar o banco / emissor do cartão",
        '1020':
          "Transação não permitida para este terminal",
        '1021':
          "Limite de saque excedido",
        '1022':
          "Transação não autorizada por violação de segurança",
        '1023':
          "Número de saques excedido",
        '1024':
          "Transação não autorizada por violação das leis",
        '1025':
          "Cartão desabilitado Oriente o usuário a contatar o banco / emissor do cartão",
        '1026':
          "Senha de bloqueio inválida",
        '1027':
          "Senha excedeu o limite de caracteres",
        '1028':
          "Erro na sincronização da senha",
        '1029':
          "Cartão com suspeita de falsificação",
        '1030':
          "Moeda não suportada pelo emissor",
        '1031':
          "Transação não autorizada, com disputa de taxas",
        '1032':
          "Cartão bloqueado por perda ou roubo",
        '1033':
          "Período para autorização solicitada não aceito",
        '1034':
          "Período da autorização expirado",
        '1035':
          "Conta encerrada",
        '1036':
          "Conta bancária encerrada",
        '1037':
          "Conta bancária encerrada",
        '1038':
          "Conta bancária encerrada",
        '1039':
          "Conta bancária encerrada",
        '1040':
          "Cartão inadimplente",
        '1041':
          "Conta com status negativo",
        '1042':
          "Conta com status negativo",
        '1043':
          "Cheque já foi compensado anteriormente",
        '1044':
          "Falta de informações no arquivo",
        '1045':
          "Código de segurança inválido",
        '1046':
          "Valor da transação não encontrado",
        '1047':
          "Oriente o usuário a alterar sua senha",
        '1048':
          "Nova senha inválida",
        '1049':
          "Banco / emissor do cartão inválido",
        '1050': "Banco / emissor do cartão não operante",
        '1051': "Fornecedor não encontrado",
        '1052': "Fornecedor inativo",
        '1053': "Conta do fornecedor inválida",
        '1054': "Fornecedor não encontrado",
        '1055': "Fornecedor inativo",
        '1056': "Dados do fornecedor inválidos",
        '1057': "Data de pagamento inválida",
        '1058':
          "Informações pessoais não encontradas",
        '1059':
          "Há transações em lista de espera",
        '1060':
          "Erro ao completar a transação no terminal",
        '1061':
          "Transação não suportada para este banco / emissor do cartão",
        '1062':
          "Estorno não permitido para esta transação",
        '1063':
          "Limite do valor estornado foi excedido",
        '1064':
          "Transação recusada e processada por um terminal offline",
        '1065':
          "Transação recusada pela impossibilidade do terminal processar a transação offline",
        '1066':
          "Transação recusada e processada offline após revisão",
        '1068':
          "Número do documento de identificação do usuário inválido",
        '1069':
          "Número do documento de identificação do usuário inválido",
        '1070':
          "Número do documento de identificação do usuário inválido",
        '1071':
          "Certificado digital expirado",
        '2000':
          "Transação recusada pelo banco, oriente o portador a contatar o banco / emissor do cartão",
        '2001':
          "Cartão vencido ou data de vencimento incorreta Oriente o usuário a contatar o banco / emissor do cartão",
        '2002': "Transação com suspeita de fraude",
        '2003':
          "Estabelecimento, favor contatar a operadora de cartão",
        '2004':
          "Cartão com restrição Oriente o usuário a contatar o banco / emissor do cartão",
        '2005':
          "Estabelecimento, favor contatar a área de risco da operadora de cartão",
        '2006':
          "O número de tentativas de senha foi excedido",
        '2007':
          "Cartão com restrição Oriente o usuário a contatar o banco / emissor do cartão",
        '2008':
          "Cartão bloqueado por perda ou roubo",
        '2009':
          "Cartão bloqueado por perda ou roubo",
        '2010': "Cartão com suspeita de falsificação",
        '2011':
          "Limite diário de saques excedido",
        '2012':
          "Valor de saque diário excedido",
        '5000':
          "Erro genérico",
        '5001':
          "Erro interno",
        '5002':
          "Modalidade de pagamento não habilitado",
        '5003':
          "Erro interno",
        '5004': "Erro interno",
        '5005':
          "O número de parcelas solicitado ultrapassa o máximo permitido para esta loja",
        '5006':
          "Erro interno",
        '5007':
          "Cartão de débito tentando utilizar produto de crédito",
        '5008':
          "Erro interno",
        '5009':
          "Erro interno",
        '5010':
          "Erro interno",
        '5011':
          "Erro interno",
        '5012':
          "Erro interno",
        '5013':
          "Erro interno",
        '5014':
          "Erro interno",
        '5015':
          "Erro interno",
        '5016':
          "O valor solicitado para captura não é válido",
        '5017':
          "Erro interno",
        '5018':
          "Erro interno",
        '5019':
          "Erro interno",
        '5020':
          "Erro interno",
        '5021':
          "Erro interno",
        '5022':
          "Erro interno",
        '5023':
          "Erro interno",
        '5024':
          "Erro interno",
        '5025':
          "Código de segurança(CVV) do cartão não foi enviado",
        '5026':
          "Erro interno",
        '5027':
          "Erro interno",
        '5028':
          "Erro interno",
        '5029':
          "Erro interno",
        '5030':
          "Erro interno",
        '5031':
          "Erro interno",
        '5032':
          "Erro interno",
        '5033':
          "Erro interno",
        '5034':
          "Campo obrigatório ano ausente",
        '5035':
          "Erro interno",
        '5036':
          "Erro interno",
        '5037':
          "Erro interno",
        '5038':
          "Erro interno",
        '5039':
          "Erro interno",
        '5040':
          "Erro interno",
        '5041':
          "Erro interno",
        '5042':
          "Erro interno",
        '5043':
          "Erro interno",
        '5044':
          "Erro interno",
        '5045':
          "Erro interno",
        '5046':
          "Valor do campo Mês inválido",
        '5047':
          "Campo Mês ausente",
        '5048':
          "A quantidade de parcelas não corresponde à transação autorizada",
        '5049':
          "Erro interno",
        '5050':
          "Erro interno",
        '5051':
          "O valor da transação excede o autorizado",
        '5052':
          "Erro interno",
        '5053':
          "Erro interno",
        '5054':
          "Erro interno",
        '5055':
          "Erro interno",
        '5056':
          "Erro interno",
        '5057':
          "Erro interno",
        '5058':
          "Erro interno",
        '5059':
          "Erro interno",
        '5060': "Erro interno",
        '5061':
          "Campo Senha ausente",
        '5062': "Erro interno",
        '5063': "Erro interno",
        '5064':
          "Erro interno",
        '5065':
          "Erro interno",
        '5066':
          "Erro interno",
        '5067':
          "Erro interno",
        '5068':
          "Erro interno",
        '5069':
          "Erro interno",
        '5070':
          "Erro interno",
        '5071':
          "Erro interno",
        '5072':
          "Erro interno",
        '5073':
          "Erro interno",
        '5074':
          "Erro interno",
        '5075':
          "Erro interno",
        '5076':
          "Erro interno",
        '5077':
          "Erro interno",
        '5078':
          "Erro interno",
        '5079':
          "Erro interno",
        '5080':
          "Erro interno",
        '5081':
          "Erro interno",
        '5082':
          "Erro interno",
        '5083':
          "Erro interno",
        '5084':
          "Erro interno",
        '5085':
          "Erro interno",
        '5086':
          "Cartão poupança inválido",
        '5087':
          "Transação não autorizada Limite diário excedido",
        '5088':
          "Transação não autorizada AmEx",
        '5089':
          "Erro interno",
        '5090':
          "Erro interno",
        '5091':
          "Transação não permitida Valor da parcela inferior ao mínimo permitido",
        '5092':
          "O valor solicitado para captura não é válido",
        '5093':
          "Transação não autorizada, oriente o portador a contatar o banco / emissor do cartão",
        '5094':
          "Venda com parcelamento não habilitado",
        '5095':
          "Erro interno",
        '5096':
          "Erro interno",
        '5097':
          "Erro interno",
        '9100':
          "Erro nos elementos do arquivo enviado",
        '9102':
          "Transação inválida Contate a operadora de cartão",
        '9103':
          "Retente a transação",
        '9105':
          "Operadora de cartão não suportada pela conexão",
        '9106':
          "Transição em processo",
        '9107':
          "O banco / emissor do cartão ou a conexão parece estar offline",
        '9108':
          "Destino da transação não encontrado",
        '9109':
          "Erro no sistema do banco ou operadora de cartão",
        '9110':
          "Banco / emissor do cartão parece estar offline",
        '9111':
          "Time out Banco / emissor do cartão não respondeu",
        '9112':
          "Banco / emissor do cartão indisponível",
        '9113':
          "Transmissão duplicada",
        '9114':
          "Não foi possível localizar a transação original",
        '9115':
          "Erro na reconciliação",
        '9116':
          "MAC incorreto",
        '9117':
          "Erro ao sincronizar a chave do MAC",
        '9118':
          "Nenhuma chave de comunicação disponível",
        '9119':
          "Erro na sincronização da chave de encriptação",
        '9120':
          "Erro de software / hardware Tente novamente",
        '9121':
          "Erro de software / hardware",
        '9122':
          "Número da mensagem fora de sequência",
        '9123':
          "Requisição em processo",
        '9124':
          "Código de segurança inválido",
        '9125':
          "Erro no banco de dados",
        '9128':
          "Formato dos dados de fornecedor inválido",
        '9132':
          "Erro na recorrência de dados",
        '9133':
          "Atualização não permitida",
        '9200':
          "Transação recusada por código irreversível - não tente novamente",
        '9201':
          "Transação recusada por excesso de retentativas",
        '9350':
          "O acordo comercial foi violado",
        '9999':
          "Erro genérico",
        'IMSG':
          "Erro genérico",
        'UNPR':
          "Erro interno",
      }
    }
  },
  mutations: {
  },
  actions: {

  }
}
