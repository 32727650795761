import Client from '@/services/Client'
const axios = new Client()
import Cookies from 'js-cookie'

export const marketing = {
  state: {
   
  },
  getters: {
    getStatusEmailsCampaigns(){
      return {
        'CREATED': 'Criado', 'PROCESSING': 'Processando', 'CANCELED': 'Cancelado', 'SENT': 'Enviado'
      }
    }
  },
  mutations: {
 
  },
  actions: {
    listEmailTemplates({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-layouts')
          .then(response => {
            response = response.data
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    findEmailTemplates({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-layouts/'+id)
          .then(response => {
            response = response.data
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    createEmailTemplate({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('marketing/email-layouts', data)
          .then(res => {
            if(res.data.id) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateEmailTemplate({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('marketing/email-layouts/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },

    cancelEmailCampaings({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.put('marketing/email-campaign/cancel/'+id)
          .then(res => {
              resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    createEmailCampaings({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('marketing/email-campaign', data)
          .then(res => {
            if(res.data.id) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createEmailSenders({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('marketing/email-identify', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    checkStatusEmailSenders({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('marketing/email-identify/check-status', data)
          .then(res => {
              resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    listEmailSenders({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-identify', data)
          .then(res => {
              resolve(res.data)
          })
          .catch(err => reject(err))
      })
    },
    listEmailCampaings({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-campaign')
          .then(response => {
            response = response.data
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    listEmailMetrics({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-campaign/metrics/'+data.email_id+'?event='+data.event)
          .then(response => {
            response = response.data
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    getEmailUsed({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-campaign/get-used')
          .then(response => {
            response = response.data
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
    getEmailTotalToSend({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.get('marketing/email-campaign/metrics/total-to-send')
          .then(response => {
            response = response.data
            resolve(response)
          })
          .catch(err => reject(err))
      })
    },
  }
}
