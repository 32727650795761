import Client from '@/services/Client'
const axios = new Client()

let uri = 'customer'
export const frete = {
  state: {
    // customers: [],
    freteStatus: {
      'released': 'Etiqueta gerada',
      'error': 'Erro',
      'created': 'Novo',
      'canceled': 'Cancelado',
      'refused': 'Sem creditos'
    }
  },
  getters: {
    getFreteStatus: state => state.freteStatus,
  },
  mutations: {
    // setCustomersBirthdays: (state, value) => state.customersBirthdays = value,
  },
  actions: {
    calculeFrete({commit, dispatch}, product) {
      return new Promise((resolve, reject) => {
        axios.post('frete/calcular-frete', product)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    gerarEtiqueta({commit, dispatch}, order) {
      return new Promise((resolve, reject) => {
        axios.post('frete/generate-tag/'+order.id, {service: order.service})
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    cancelEtiqueta({commit, dispatch}, orderEtiqueta) {
      return new Promise((resolve, reject) => {
        axios.post('frete/canceled/'+orderEtiqueta)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    getServicesByContract({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post('frete/correios/services', data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    getPrintByToken({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        let uri ='frete/get-etiqueta/'+data.token
        if(data){
          let query = new URLSearchParams(data).toString();
          
          uri += query ? '?'+query : '';
        }
        axios.get(uri, data)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    getRastreio({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        let uri ='frete/get/rastreio/'+data.id
      
        axios.get(uri)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
  }
}
