import Client from '@/services/Client'
const axios = new Client()

export const seller = {
  state: {
    sellers: [],
    loadTableSeller: true,
    overFloawTotalSeller: true,
    salesTotalSeller: {},
    loadSkeletonTotalSeller: true,
    reportSeller: {},
  },
  getters: {
    getSellers: state => state.sellers,
    getLoadTableSeller: state => state.loadTableSeller,
    getSalesTotalSeller: state => state.salesTotalSeller,
    getOverFloawTotalSeller: state => state.overFloawTotalSeller,
    getLoadSkeletonTotalSeller: state => state.loadSkeletonTotalSeller,
    getReportSeller: state => state.reportSeller,
   
  },
  mutations: {
    setSellers: (state, value) => state.sellers = value,
    setLoadTableSeller: (state, value) => state.loadTableSeller = value,
    setOverFloawTotalSeller: (state, value) => state.overFloawTotalSeller = value,
    setSalesTotalSeller: (state, value) => state.salesTotalSeller = value,
    setLoadSkeletonTotalSeller: (state, value) => state.loadSkeletonTotalSeller = value,
    setReportSeller: (state, value) => state.reportSeller = value,
  },
  actions: {
    listSellers({ commit, dispatch }, url) {
      if(url.load !== false){
        commit('setShowSkeletonTable', true)
        dispatch('loadTableSeller', true)
      }
      return new Promise((resolve, reject) => {

        let uri = 'seller'
        if (url) {
          let query = new URLSearchParams(url).toString();
          uri += query ? '?' + query : '';
        }

        axios.get(uri)
          .then(res => {
            if (res.data) {
              commit('setSellers', res.data)
            }
            resolve(res)
          })
          .catch(err => {
            reject(err)

          }).finally(() => {
            if(url.load !== false){
              dispatch('loadTableSeller', false)
              commit('setShowSkeletonTable', false)
            }
          })
      })
    },
    loadTableSeller({ commit }, isLoad) {
      commit('setLoadTableSeller', isLoad)
    },
    overFloawTotalSeller({ commit }, isLoad) {
      commit('setOverFloawTotalSeller', isLoad)
    },
    getTotalToHeaderSeller({ commit, dispatch }, url) {

      dispatch('overFloawTotalSeller', true)

      return new Promise((resolve, reject) => {

        let uri = 'seller/total-to-header'
        if (url) {
          let query = new URLSearchParams(url).toString();
          uri += query ? '?' + query : '';
        }

        axios.get(uri)
          .then(res => {
            commit('setSalesTotalSeller', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            dispatch('overFloawTotalSeller', false)
          })
      })
    },
    getReport({ commit, dispatch }, url) {

      dispatch('overFloawTotalSeller', true)

      return new Promise((resolve, reject) => {

        let uri = 'seller/report'
        if (url) {
          let query = new URLSearchParams(url).toString();
          uri += query ? '?' + query : '';
        }

        axios.get(uri)
          .then(res => {
            commit('setReportSeller', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            dispatch('overFloawTotalSeller', false)
          })
      })
    }
  }
}
