import Client from '@/services/Client'
const axios = new Client()

export const permissionGroup = {
  state: {
    permissionsGroups: [],
    permissionGroup: {
      name: null,
      permissions: []
    },
    linksPermissionGroups: []
  },
  getters: {
    getPermissionGroups: state => state.permissionsGroups,
    getPermissionGroup: state => state.permissionGroup,
    getLinksPermissionGroups: state => state.linksPermissionGroups,
  },
  mutations: {
    setPermissionGroups: (state, value) => state.permissionsGroups = value,
    addPermissionGroup: (state, value) => state.permissionsGroups.push(value),
    editPermissionGroups: (state, {index, data}) => state.permissionsGroups.splice(index, 1, data),
    removePermissionGroup: (state, id) => state.permissionsGroups = state.permissionsGroups.filter(x => x.id !== id),
    setPermissionGroup: (state, value) => state.permissionGroup = value,
    setLinksPermissionGroups: (state, value) => state.linksPermissionGroups = value,
  },
  actions: {
    updatePermissionGroups: ({commit}, data) => commit('setUsers', data),
    listPermissionGroups({commit}, url) {
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then(res => {
          if(res.data) {
            commit('setPermissionGroups', res.data)
          }
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    createPermissionGroup({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('permission-group', data)
          .then(res => {
            if(res.data.id) {
              let permissionGroup = { ...data, id: res.data.id, total: 0 }
              commit('addPermissionGroup', permissionGroup)
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    putPermissionGroups({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`permission-group/${data.id}`, data)
          .then(res => {
            const index = state.permissionsGroups.indexOf(
              state.permissionsGroups.find(x => x.id === data.id)
            )
            commit('editPermissionGroups', {index, data})
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    deletePermissionGroup({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`permission-group/${data.id}`)
          .then(res => {
            commit('removePermissionGroup', data.id)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    updateLinksPermissionGroups: ({commit}, data) => commit('setLinksPermissionGroups', data),
    updatePermissionGroup: ({commit}, data) => commit('setPermissionGroup', data),
    showPermissionGroup({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`permission-group/${id}`)
        .then(res => {
          let data = {
            id: res.data.id,
            name: res.data.name,
            permissions: []
          }

          let arrayRole = Object.values(res.data.group_role)
          arrayRole.forEach(x => data.permissions.push({'role_id': x.role_id}))

          commit('setPermissionGroup', data, res)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
  }
}
