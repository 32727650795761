import Client from '@/services/Client'
const axios = new Client()

export const payment = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    payment({ commit , dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post('payment', data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    findPayment({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get('plan-charges/'+id)
        .then(res => {
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    transactions({ commit , dispatch}, data) {
      commit('setShowSkeletonTable', true)
      return new Promise((resolve, reject) => {

        let uri = 'plan-charges'
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('setShowSkeletonTable', false)
          })
      })
    },
  }
}
