import Client from '@/services/Client'
const axios = new Client()

export const colletion = {
  state: {
   collections: [],
   notColleting: [],
  },
  getters: {
    getCollections: state => {
      let  dataOrders = {}
      state.collections.forEach((item)=>{
        if(!dataOrders[item.provider_id]){
            dataOrders[item.provider_id] = []
        }
        dataOrders[item.provider_id].push(item)
      })
     
      return dataOrders;
    },
    getNotCollecting: state => state.notColleting,
    getCollectionsStatus() {
      return {
        'FINISHED': 'Finalizado',
        'CANCELED': 'Cancelado',
        'COLLECTING': 'Coletando',
        'TO_COLLECTING': 'A coletar'
      }
    }
  },
  mutations: {
   setCollections: (state, value) => state.collections = value,
   setNotColleting: (state, value) => state.notColleting = value,
  },
  actions: {
    colletionList({commit, getters}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'collections'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            commit('setNotColleting', res.data.not_collecting)
            commit('setCollections', res.data.collections)
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    colletionCreate({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'collections'
        
        axios.post(uri, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    colletionUpdate({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'collections'
        
        axios.put(uri+'/'+data.id, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    colletionUpdateAll({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'collections'
        
        axios.post(uri+'/finished-all', {'collections': data})
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
  }
}
