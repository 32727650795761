import Client from '@/services/Client'
const axios = new Client()

export const movementReport = {
  state: {
   
  },
  getters: {
    
  },
  mutations: {
   
  },
  actions: {
    getReportMovement({ commit, dispatch }, data) {
      
      return new Promise((resolve, reject) => {
        let uri = 'movement/report/'+data.uri
        if(data){
          let query = new URLSearchParams(data).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(() =>{
          })
      })
    },
  }
}
