import Client from '@/services/Client'
const axios = new Client()

export const people = {
  state: {
   
  },
  getters: {
    
  },
  mutations: {
   
  },
  actions: {
    peopleList({commit, getters}) {
      return new Promise((resolve, reject) => {
        let uri = 'peoples'
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    }
  }
}
