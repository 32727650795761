import Client from '@/services/Client'
const axios = new Client()

export const register = {
  state: {
    register: {
      name: null,
      email: null,
      password: null,
      password_confirmation: null,
      accepted_term_of_use: false,
      phone: null,
      type: null,
    }
  },
  getters: {
    getRegister: state => state.register,
  },
  mutations: {
    setRegister: (state, value) => state.register = value
  },
  actions: {
    updateRegister: ({commit}, data) => commit('setRegister', data),
    createNewUser({state}, data) {

      return new Promise((resolve, reject) => {
        axios.post('register', data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err =>reject(err))
      })
    },
    onSubmitSendLinkConfirmeAccount({state}, data) {
      return new Promise((resolve, reject) => {
        return axios.post('send-email/confirm/account', data)
          .then(res => resolve(res))
          .catch(err => reject(err))
      })
    },
    onSubmitSendLinkConfirmed({state}, data) {
      return new Promise((resolve, reject) => {
        return axios.post('send-email/confirm/account/confirm', data)
          .then(res => resolve(res.data))
          .catch(err => reject(err))
      })
    },
    // createSale({ commit, getters }, data){

    //   if(!getters.permission('order.create')){
    //     console.error('Sem permissão para acessar!');
    //     return false;
    //   }

    //   return new Promise((resolve, reject) => {
    //     axios.post('order', data)
    //       .then(res => {
    //         if(res.data) {

    //           resolve(res)
    //         } else reject(res)
    //       })
    //       .catch(err => reject(err))
    //   })
    // },
  }
}
