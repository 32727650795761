import Client from '@/services/Client'
const axios = new Client()

export const boardTask = {
  state: {
    boards: [],
    boardTask: [],
    boardId: null,
    tags: [],
  },
  getters: {
    getBoards: state => state.boards,
    getBoardId: state => state.boardId,
    getTags: state => state.tags,
  },
  mutations: {
    setBoards: (state, value) => state.boards = value,
    setBoardId: (state, value) => state.boardId = value,
    setTags: (state, value) => state.tags = value,
  },
  actions: {
    boards({ commit , dispatch, getters}, url) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)
        let uri = 'boards'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            if( res.data[0]){
              commit('setBoardId', res.data[0].board_id)
            }
            commit('setBoards', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    updateTask({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)

        let uri = 'boards/task/'+data.id
 
        axios.put(uri, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    craeteMetaTask({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)

        let uri = 'boards/task/metas'
 
        axios.post(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    createTag({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {

        let uri = 'boards/tags'
 
        axios.post(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    listTag({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'boards/tags/'+data.board_id
        axios.get(uri, data)
          .then(res => {
            commit('setTags', res.data)
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    createTaskTag({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'boards/task/tags'
        axios.post(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },

    createTaskService({ commit , dispatch, getters}, orderId) {
      return new Promise((resolve, reject) => {
        let uri = 'boards/service/'+orderId
        axios.post(uri, orderId)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },

    deleteTaskTag({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'boards/task/tags/'+data.board_tag_id+'/'+data.board_task_id
        axios.delete(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    updateTag({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'boards/tags/'+data.id
 
        axios.put(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    deleteTag({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)

        let uri = 'boards/tags/'+data.id
 
        axios.delete(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    findTask({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)

        let uri = 'boards/task/'+data.id
 
        axios.get(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },

  }
}
