import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
import NotFound from '@/views/NotFoundPage.vue';
import Cookie from 'js-cookie'
import store from '@/store/index'



function authentication(to, from, next) {

  
  if (Cookie.get('isLogged')) {

    if (!Cookie.get('roles')) {
      return next(`/${window.location.search}login`)
    }

    const roleData = JSON.parse(Cookie.get('roles'));

    let role = roleData.find((item) => {
      const [view, permission] = item.name.split('.');
      const viewName = view == to.name.split('-')[0]

      if (viewName) {
        if (typeof to.meta.permission == 'string' && permission === to.meta.permission) {
          return item;
        } else if (typeof to.meta.permission == 'object' && permission === to.meta.permission.find(item => item == permission)) {
          return item;
        }
      }

    })

    if (!role) {
      // + from.fullPath.substr(1)
      return next(`/default/dashboard`)
    }

    return next()
  }

  return next(`/${window.location.search}login`)
}


const routes = [
  {
    path: '/',
    redirect: 'dashboard',
    component: DashboardLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Dashboard.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/default/dashboard',
        name: 'dashboardDefault',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/DashboardDefault.vue'),
      },
      {
        path: '/balcao/vendas',
        name: 'pdv',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/PDV.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/pedidos',
        name: 'order',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Order.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/coletas',
        name: 'coleta',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Coletor.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/envios',
        name: 'frete-read',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Freight.vue'),
        beforeEnter: authentication,
      },


      {
        path: '/relatorios',
        name: 'report',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Report.vue'),
        beforeEnter: authentication,
      },

      {
        path: '/marketplace',
        name: 'marketplace',
        // meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Marketplace.vue'),
        // beforeEnter: authentication,
      },
      
      {
        path: '/vendedores',
        name: 'seller',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Seller.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/integracao',
        name: 'integracao',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Integracao.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/usuarios',
        name: 'user',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/User.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/usuario/cadastro',
        name: 'user-create',
        meta: { permission: 'create' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/User/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/usuario/:id/editar/',
        name: 'user-edit',
        meta: { permission: 'update' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/User/Form.vue'),
        beforeEnter: authentication,
      },

      {
        path: '/clientes',
        name: 'customer',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Customer.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/cliente/cadastro',
        name: 'customer-create',
        meta: { permission: 'create' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Customer/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/cliente/:id/editar/',
        name: 'customer-update',
        meta: { permission: 'update' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Customer/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/fornecedor',
        name: 'provider',
        meta: { permission: 'read' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '../views/Fornecedor.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/fornecedor/cadastro',
        name: 'provider-create',
        meta: { permission: 'create' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Fornecedor/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/fornecedor/:id/editar',
        name: 'provider-update',
        meta: { permission: 'update' },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Fornecedor/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/produtos',
        name: 'product',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Product.vue'),
        beforeEnter: authentication,
      },
      {
        path: 'produtos/compras',
        name: 'product-compras',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductPurchase.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/nova/compra',
        name: 'nova-compras',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductCompras.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/produto/cadastro',
        name: 'product-create',
        meta: { permission: 'create' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Product/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/orcamentos',
        name: 'budget-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "budgetIndex" */'@/views/Budget.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/produto/:id/editar',
        name: 'product-update',
        meta: { permission: 'update' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Product/Form.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/produtos/entradas',
        name: 'productEntrada-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductEntrada.vue'),
        // beforeEnter: authentication,
      },


      {
        path: '/produtos/movimentacao',
        name: 'product-movement-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductMovement.vue'),
        // beforeEnter: authentication,
      },

      
      {
        path: '/produtos/estoques',
        name: 'productEstoque-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductEstoques.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/produtos/saidas',
        name: 'productSaida-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductSaida.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/produto-itens',
        name: 'productItems-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ProductItem.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/vendas',
        name: 'sale',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Venda.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/devolucao',
        name: 'devolucao-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Devolucao.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/devolucao/cadastro/:type/:code',
        name: 'devolucao-create',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Devolution/Products.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/producoes',
        name: 'producoes',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Producao.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/notas',
        name: 'taxNote-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/TaxNote.vue'),
        beforeEnter: authentication,
      },
      // TaxNote
      {
        path: '/venda/cadastro/:orcamento?',
        name: 'order-create',
        meta: { permission: 'create' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/PDV/Pdv.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/faturas-assinaturas',
        name: 'subscription',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Settings.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/movimentacoes',
        name: 'movements-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/Movement.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/relatorio/movimentacoes/',
        name: 'movementsreport-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/MovementReport.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/conta-bancaria',
        name: 'movements-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/ContaBancaria.vue'),
        beforeEnter: authentication,
      },

      {
        path: '/contas-a-receber',
        name: 'movements-read-a-receber',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/MovementAReceber.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/contas-a-pagar',
        name: 'movements-read-a-pagar',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/MovementAPagar.vue'),
        beforeEnter: authentication,
      },
      {
        path: 'criar/contas-a-receber',
        name: 'movements-create-a-receber',
        meta: { permission: 'create', type: 'ENTRADA' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Movement/RegisterMoviment.vue'),
        beforeEnter: authentication,
      },
      {
        path: 'criar/contas-a-pagar',
        name: 'movements-create-a-pagar',
        meta: { permission: 'create', type: 'SAIDA' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Movement/RegisterMoviment.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/contas-a-pagar/:id/edit',
        name: 'movements-edit-a-pagar',
        meta: { permission: 'create', type: 'SAIDA' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Movement/RegisterMoviment.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/contas-a-receber/:id/edit',
        name: 'movements-edit-a-receber',
        meta: { permission: 'create', type: 'ENTRADA' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Movement/RegisterMoviment.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/transaction/order/:id',
        name: 'movements-transaction-order',
        meta: { permission: 'read'},
        component: () => import(/* webpackChunkName: "demo" */ '../views/TransactionOrder.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/plano-de-contas',
        name: 'movements-read-plano-contas',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/MovementPlanoDeContas.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/centro-de-custo',
        name: 'costCenter-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/MovementCostCenter.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/email/marketing',
        name: 'emailMarketing-read',
        meta: { permission: 'read' },
        component: () => import(/* webpackChunkName: "demo" */ '../views/EmailMarketing.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/email/marketing/create',
        name: 'emailMarketing-create',
        meta: { permission: 'create' },
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Marketing/ConfigEmailMarketing.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/email/marketing/:id/editar',
        name: 'emailMarketing-editar',
        component: () => import(/* webpackChunkName: "demo" */ '@/components/Pages/Marketing/ConfigEmailMarketing.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/icons',
        name: 'icons',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Icons.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/profile',
        name: 'profile',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/UserProfile.vue'),
        // beforeEnter: authentication,
      },
      {
        path: '/maps',
        name: 'maps',
        component: () => import(/* webpackChunkName: "demo" */ '../views/GoogleMaps.vue'),
        beforeEnter: authentication,
      },
      {
        path: '/tables',
        name: 'tables',
        component: () => import(/* webpackChunkName: "demo" */ '../views/RegularTables.vue'),
        // beforeEnter: authentication,
      }
    ],
    
  },
  {
    path: '/',
    path: '/imprimir/:token',
    name: 'Etiquetas',
    component: () => import(/* webpackChunkName: "demo" */ '../views/FreightEtiquetas.vue'),
  },
  {
    path: '/',
    path: '/cliente/cadastro/:token',
    name: 'Etiquetas-token',
    component: () => import(/* webpackChunkName: "demo" */ '../views/CustomerExternalRegister.vue'),
  },
  {
    path: '/',
    path: '/cliente/cadastro/feedback/:token',
    name: 'Etiquetas-feedback',
    component: () => import(/* webpackChunkName: "demo" */ '../views/CustomerExternalRegisterFeedBack.vue'),
  },
  {
    path: '/',
    path: '/produtos/catalogo/:hash',
    name: 'Catalogo',
    component: () => import(/* webpackChunkName: "demo" */ '../views/Catalogo/Produto/Produtos.vue'),
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/Register.vue')
      },
      {
        path: '/confirma/email',
        name: 'confirma-email',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/ConfirmEmail.vue')
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/ForgotPassword.vue')
      },
      {
        path: '/recovery-password/:email/:hash',
        name: 'recovery-password',
        component: () => import(/* webpackChunkName: "demo" */ '../views/Pages/RecoveryPassword.vue')
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
