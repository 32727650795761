<template>
  <div>
    <b-modal
      id="modal-show-notification"
      title="Notificações"
      scrollable
      class="h-100"
    >

      <div class="card p-3" >
        <a href="https://www.evolveresales.com.br/comisao.html" target="_blank" v-if="permission('user.commission')" style="color: black;">
          <span><strong> COMISSÃO DE VENDAS PERSONALIZADA POR USUÁRIO</strong></span>
          <br>
          <small>Apresentamos a nova funcionalidade de Comissão de Vendas Personalizada por Usuário, <br> uma solução desenhada para atender essa demanda específica.</small>
        </a>
      </div>

      <template #modal-footer>
        <div>
          
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ShowNotification",
  components: {
  },
  data() {
    return {
      
    };
  },
  watch: {

  },
  computed: {
    ...mapGetters([
      "permission",
    ]),
  },
};
</script>

<style scoped>
.content-btn-show-itens > button {
  width: 100%;
}
</style>

<style>
@media only screen and (min-width: 1016px) {
  #modal-show-order > div {
    float: right;
    width: 35%;
    height: 100vh;
    margin: 0;
  }

  #modal-show-order > div > div {
    float: right;
    height: 100vh;
  }

  .title {
    font-weight: 600;
  }
}
</style>
