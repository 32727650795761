<template>
    <div class="">
        <!-- <b-dropdown-header class="noti-title">
            <h6 class="text-overflow m-0">Bem vindo!</h6>
        </b-dropdown-header> -->

            <b-dropdown-item to="/profile">
                <i class="ni ni-single-02"></i>
                <span>Perfil</span>
            </b-dropdown-item>
            <b-dropdown-item to="/marketplace"  v-if="isMobile">
                <i class="ni ni-shop"></i>
                <span>Planos</span>
            </b-dropdown-item> 
            <b-dropdown-item to="/faturas-assinaturas"  v-if="permission('subscription.read')" >
                <i class="fas fa-solid fa-coins"></i>
                <span>Minhas Faturas</span>
            </b-dropdown-item> 

            <div class="dropdown-divider"></div>    
            <b-dropdown-item @click="logout">
                <i class="ni ni-user-run"></i>
                <span>Sair</span>
            </b-dropdown-item>

        <!-- <sidebar-item v-if="permission('subscription.read')" :activeClass="isCurrentRoute('Setting-sale')" :link="{
                name: 'Faturas e Assinaturas',
                path: '/faturas-assinaturas',
                icon: 'fas fa-solid fa-coins',
              }">
              </sidebar-item> -->
              <!-- <sidebar-item :activeClass="isCurrentRoute('Setting-sale')" :link="{
                name: 'Perfil',
                path: '/profile',
                icon: 'ni ni-single-02',
              }">
              </sidebar-item> -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name:'DropItemMenu',
    computed: {
        ...mapGetters(['permission', 'isMobile']),
    },
    methods:{
        logout(){
            this.$store.dispatch('logoutUser')
        }
    }
}


</script>