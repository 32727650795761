import Client from '@/services/Client'
const axios = new Client()

let uri = 'customer'
export const customer = {
  state: {
    customers: [],
    customer: [],
    files: [],
    customersBirthdays: [],
  },
  getters: {
    getFiles: state => state.files,
    getCustomers: state => state.customers,
    getCustomer: state => state.customer,
    getCustomersBirthdays: state => state.customersBirthdays,
  },
  mutations: {
    setFiles: (state, value) => {
      if(value.reset){
        state.files = []
      }else{
        state.files.push(value)
      }
      
      return state.files;
    },
    setRemoveFiles: (state, file) => {

      state.files = state.files.map((item) => {

        if (item.lastModified !== file.lastModified) {
          return item;
        }
      }).filter((item) => {
        if (item) {
          return item;
        }
      })

    },
    setCustomers: (state, value) => state.customers = value,
    setCustomer: (state, value) => state.customer = value,
    setCustomersBirthdays: (state, value) => state.customersBirthdays = value,
    
  },
  actions: {
    // Customers
    findCustomers({commit, dispatch}, id) {

      return new Promise((resolve, reject) => {
        axios.get('customer/show/'+id)
        .then(res => {
          dispatch('setFilesData', {reset:true})
          commit('setCustomer', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    listCustomers({commit}, url) {
      if(url.load !== false){
        commit('setShowSkeletonTable', true)
      }
   
      let query = new URLSearchParams(url).toString();
      query = query ? '?'+query : '';

      return new Promise((resolve, reject) => {
        axios.get(url.url ? url.url+query : 'customer'+query)
        .then(res => {
          commit('setCustomers', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
          if(url.load !== false){
            commit('setShowSkeletonTable', false)
          }
        })
      })
    },
    listCustomerTotalToHeader({commit}, url) {
      return new Promise((resolve, reject) => {

        uri = 'customer/total-to-header'

        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
        })
      })
    },
    birthdays({commit}, url) {

      return new Promise((resolve, reject) => {
        axios.get('customer/birthday')
        .then(res => {
          commit('setCustomersBirthdays', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    },
    createCustomer({commit, dispatch}, data){
      return new Promise((resolve, reject) => {
        axios.post('customer', data)
          .then(res => {
            if(res.data) {
              dispatch('setFilesData', {reset:true})
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createPublicCustomer({commit, dispatch}, data){
      return new Promise((resolve, reject) => {
        axios.post('public/customer/register', data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateCustomer({commit, dispatch}, data){
      return new Promise((resolve, reject) => {
        axios.put('customer/'+data.customer.id, data)
          .then(res => {
            if(res.data.id) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createFile({commit, dispatch}, data){
      return new Promise((resolve, reject) => {
        axios.post('customer/file', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    removeFile({commit, getters}, file){
      if(file.id){
        return new Promise((resolve, reject) => {
          axios.delete('customer/file/'+file.id)
            .then(res => {
              if(res.data) {
                commit('setRemoveFiles', file)
                resolve(res)
              } else reject(res)
            })
            .catch(err => reject(err))
        })
      }
      return commit('setRemoveFiles', file)
    },
    setFilesData({commit}, data){
      if(!data)return
      return commit('setFiles', data?data:[])
    }
  }
}
