import Client from '@/services/Client'
const axios = new Client()

export const unitsOfMenasurement = {
  state: {
    unitsOfMenasurements: [],
  },
  getters: {
    getUnitsOfMenasurements: state => state.unitsOfMenasurements,
  },
  mutations: {
    setUnitsOfMenasurements: (state, value) => state.unitsOfMenasurements = value,
  },
  actions: {
    listUnitsOfMenasurement({ commit , dispatch, getters}, url) {
      return new Promise((resolve, reject) => {
        // commit('setShowSkeletonTable', true)
        let uri = 'units-measurement'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            commit('setUnitsOfMenasurements', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    updateUnitsOfMenasurement({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        // commit('setShowSkeletonTable', true)

        let uri = 'units-measurement/'+data.id
 
        axios.put(uri, data)
          .then(res => {
            // commit('setUnitsOfMenasurements', {...getters.getUnitsOfMenasurements, res})

            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            // commit('setShowSkeletonTable', false)
          })
      })
    },
    createUnitsOfMenasurement({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        // commit('setShowSkeletonTable', true)

        let uri = 'units-measurement'
 
        axios.post(uri, data)
          .then(res => {
            let dataGetter = getters.getUnitsOfMenasurements
            dataGetter.push(res.data)

            commit('setUnitsOfMenasurements', dataGetter)

            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
            // commit('setShowSkeletonTable', false)
          })
      })
    },
    deleteUnitsOfMenasurement({ commit , dispatch, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'units-measurement/'+data.id
        axios.delete(uri, data)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    }

  }
}
