<template>
        <b-navbar-nav class="align-items-center ml-auto ml-md-0">
            <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0" > 
            
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
                <b-media no-body class="align-items-center">
                <b-media-body class="ml-2 d-none d-lg-block" >
                    <span class="font-weight-600 description mb-0 text-sm font-weight-bold" v-if="getUserLogged.id">
                    <i class="ni ni-bell-55 " :class="{'text-dark': !isMobile}"></i>
                    </span>
                </b-media-body>
                </b-media>
            </a>
            
            <b-dropdown-item href="https://www.evolveresales.com.br/comisao.html" target="_blank" v-if="permission('user.commission')">
                <div class="card p-3">
                    <span><strong> COMISSÃO DE VENDAS PERSONALIZADA POR USUÁRIO</strong></span>
                    <small>Apresentamos a nova funcionalidade de Comissão de Vendas Personalizada por Usuário, <br> uma solução desenhada para atender essa demanda específica.</small>
                </div>
            </b-dropdown-item>
            
            </base-dropdown>
        </b-navbar-nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'Notification',
    computed: {
        ...mapGetters(['getUserLogged', 'permission', 'isMobile']),
    }
}

</script>