import Client from '@/services/Client'
const axios = new Client()
import Cookies from 'js-cookie'

export const company = {
  state: {
    company: {},
  },
  getters: {
    getCompany: state => state.company,
  },
  mutations: {
    setCompany: (state, value) => state.company = value,
  },
  actions: {
    findCompanyData({commit}) {
      return new Promise((resolve, reject) => {
        axios.get('company')
          .then(response => {
            commit('setCompany', response.data)
            return resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    upddateCompany({commit, dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.put('company/'+data.id, data)
          .then(response => {
            dispatch('me')
            return resolve(response.data)
          })
          .catch(err => reject(err))
      })
    },
    // findUser({commit}, data) {

    //   return new Promise((resolve, reject) => {
    //     axios.get('user/show/'+data.id)
    //       .then(response => {
    //         return resolve(response.data)
    //       })
    //       .catch(err => reject(err))
    //   })
    // },
  }
}
