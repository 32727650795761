import Client from '@/services/Client'
const axios = new Client()

export const movementAccountPlan = {
  state: {
    movementSubAccountPlan: [],
  },
  getters: {
    getMovementSubAccountPlan: state => state.movementSubAccountPlan,
  },
  mutations: {
    setMovementSubAccountPlan: (state, value) => state.movementSubAccountPlan = value,
  },
  actions: {
    listMovementSubAccountPlan({commit}, url) {
      commit('setShowSkeletonTable', true)
      return new Promise((resolve, reject) => {
        let uri = 'movement/subaccount-plan'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
        .then(res => {
          commit('setMovementSubAccountPlan', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
        .finally(()=>{
          commit('setShowSkeletonTable', false)
        })
      })
    },
    searchMovementSubAccountPlan({commit}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'movement/subaccount-plan'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
        .then(res => {
          resolve(res.data)
        })
        .catch(err => reject(err))
      })
    }
  }
}
