import Client from '@/services/Client'
const axios = new Client()

export const role = {
  state: {
    roles: [],
    role: {
      title: null,
      description: null,
      name: null
    },
    linksRoles: []
  },
  getters: {
    getRoles: state => state.roles,
    getRolesOptions(state) {
      return state.roles.reduce((acc, item) => {
        (acc[item['title']] = acc[item['title']] || []).push(item)
        return acc
      }, {})
    },
    getRolesOptionsErp(state) {
      let groupBy = state.roles.reduce((acc, item) => {
        (acc[item['title']] = acc[item['title']] || []).push(item)
        return acc
      }, {})
      return Object.keys(groupBy)
    },
    getRole: (state, data) => state.role,
    getLinksRoles: state => state.linksRoles,
  },
  mutations: {
    setRoles: (state, value) => state.roles = value,
    addRole: (state, value) => state.roles.push(value),
    editRoles: (state, {index, data}) => state.roles.splice(index, 1, data),
    removeRole: (state, id) => state.roles = state.role.filter(x => x.id !== id),
    setRole: (state, value) => state.role = value,
    setLinksRoles: (state, value) => state.linksRoles = value,
  },
  actions: {
    updateRoles: ({commit}, data) => commit('setUsers', data),
    listRoles({commit}, url) {
      return new Promise((resolve, reject) => {
        axios.get(url)
        .then(res => {
          if(Array.isArray(res.data)) {
            commit('setRoles', res.data)
          } else {
            commit('setRoles', res.data.data)
            commit('setLinksRoles', res.data.links)
          }
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    createRole({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('role', data)
          .then(res => {
            if(res.data.id) {
              commit('addRole', res.data)
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    putRoles({commit, state}, data) {
      return new Promise((resolve, reject) => {
        axios.put(`role/${data.id}`, data)
          .then(res => {
            const index = state.roles.indexOf(
              state.roles.find(x => x.id === data.id)
            )
            commit('editRoles', {index, data})
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    deleteRole({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`role/${data.id}`)
          .then(res => {
            commit('removeRole', data.id)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    updateLinksRoles: ({commit}, data) => commit('setLinksRoles', data),
    updateRole: ({commit}, data) => commit('setRole', data),
    showRole({commit}, id) {
      return new Promise((resolve, reject) => {
        axios.get(`role/${id}`)
        .then(res => {
          commit('setRole', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    }
  }
}
