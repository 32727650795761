import Client from '@/services/Client'
const axios = new Client()

export const sale = {
  state: {
    sales: [],
    saleTotal: {
      amount: 0,
      total_for_seller: 0,
      total_liquido: 0
    },
    saleFilter:{
      search: '',
      date_type: 'created_at',
      start_date: '',
      end_date: '',
    },
    statusSaleOBJ: {
      NEW_SALE: 'Nova venda',
      CONFIRMED: 'Confirmado',
      CANCELED: 'Cancelado',
      DEVOLUTION: 'Devolvido'
    },
    paymentStatus: {
      waiting_payment: 'Aguardando pagamento',
      awaiting_payment: 'Aguardando pagamento',
      paid: 'Pago',
      // refused: 'Recusado',
      // refunded: 'Reembolsado',
      canceled: 'Cancelado',
    },
    loadSkeletonSale: true,
    loadTableSale: true,
  },
  getters: {
    getSales: state => state.sales,
    getSalesTotal: state => state.saleTotal,
    getsaleFilter: state => state.saleFilter,
    getStatusSale: state => state.statusSaleOBJ,
    getPaymentStatus: state => state.paymentStatus,
    getLoadSkeletonTotalSale: state => state.loadSkeletonSale,
    getLoadTableSale: state => state.loadTableSale,
  },
  mutations: {
    setSales: (state, value) => state.sales = value,
    setSalesTotal: (state, value) => state.saleTotal = value,
    setsaleFilter: (state, value) => state.saleFilter = value,
    setLoadSkeletonTotalSale: (state, value) => state.loadSkeletonSale = value,
    setLoadTableSale: (state, value) => state.loadTableSale = value,
  },
  actions: {
    getTotalToHeader({ commit, getters }, url) {
      commit('setLoadSkeletonTotalSale', true)
      if(!getters.permission('sale.read')){
        console.error('Sem permissão para acessar!');
        return false;
      }
        
      return new Promise((resolve, reject) => {

        let uri = 'sale/total-to-header'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
          .then(res => {
            commit('setSalesTotal', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setLoadSkeletonTotalSale', false)
          })
      })
    },
    listSales({commit, getters}, url) {
      commit('setShowSkeletonTable', true)
      if(!getters.permission('sale.read')){
        console.error('Sem permissão para acessar!');
        return false;
      }
      commit('setLoadTableSale', true)

      return new Promise((resolve, reject) => {

        let uri = 'sale'
        if(url){
          uri = 'sale'
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
            commit('setSales', res)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setLoadTableSale', false)
            commit('setShowSkeletonTable', false)
          })
      })
    },
   
    changeStatusOrder({ getters , dispatch}, data){

      if(!getters.permission('sale.update')){
        console.error('Sem permissão para acessar!');
        return false;
      }

      return new Promise((resolve, reject) => {
        axios.put('sale/status/'+data.id, data)
          .then(res => {
            if(res.data) {
              dispatch('listSales', getters.getsaleFilter)
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    changePaymentStatus({ commit , dispatch, getters}, data){
      if(!getters.permission('sale.update')){
        console.error('Sem permissão para acessar!');
        return false;
      }
      return new Promise((resolve, reject) => {
        axios.put('sale/payment-status/'+data.id, data)
          .then(res => {
            if(res.data) {
              // dispatch('listSales')
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    findSale({ commit , dispatch, getters}, id){
      if(!getters.permission('sale.read')){
        console.error('Sem permissão para acessar!');
        return false;
      }
      return new Promise((resolve, reject) => {
        axios.get('sale/'+id)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    setFilterSale({ commit }, data){
      commit('setsaleFilter', data)
    },
    removeProductsOfOrder({dispatch}, data) {
      return new Promise((resolve, reject) => {
        axios.post(`sale/remove-products/${data.saleId}`, data).then(res => {
          if (res.data) {
            dispatch('listProductsOfOrder', data.orderId);
            resolve(res)
          } else reject(res);
        }).catch(err => reject(err));
      });
    },
  }
}
