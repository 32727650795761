import Client from '@/services/Client'
const axios = new Client()

export const carrier = {
  state: {
   
  },
  getters: {
    
  },
  mutations: {
   
  },
  actions: {
    carrierList({commit, getters}, url) {
      return new Promise((resolve, reject) => {
        let uri = 'carrier'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    carrierCreate({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'carrier'
        
        axios.post(uri, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    carrierUpdate({commit, getters}, data) {
      return new Promise((resolve, reject) => {
        let uri = 'carrier'
        
        axios.put(uri+'/'+data.id, data)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
  }
}
