import Client from '@/services/Client'
const axios = new Client()

export const saleSettings = {
  state: {
    saleSettings: [],
  },
  getters: {
    getSaleSettings: state => state.saleSettings,
    getTypeSaleSettings: ()=>{
      return [
        { value: null, text: 'Selecione uma configuração' },
        { value: 'ONLINE_SALE', text: 'Online' },
        { value: 'LOCAL_SALE', text: 'Presencial' },
      ]
    }
  },
  mutations: {
    setSaleSettings: (state, value) => state.saleSettings = value,
  },
  actions: {
    listSaleSettings({ commit }, url) {
      return new Promise((resolve, reject) => {
        axios.get('sale-settings')
          .then(res => {
            commit('setSaleSettings', res)
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    createSaleSetting({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.post('sale-settings', data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateSaleSetting({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.put('sale-settings/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    deleteSaleSetting({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.delete('sale-settings/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
