import Client from '@/services/Client'
const axios = new Client()

export const exchanteReturnTransaction = {
  state: {
   
  },
  getters: {
    
  },
  mutations: {
   
  },
  actions: {
    exportTorcasEDevolucoes({commit, getters}, url) {

      return new Promise((resolve, reject) => {

        let uri = 'exchante-return-transaction/report/export'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    listExchanteReturnTransaction({commit, getters}, url) {
      commit('setShowSkeletonTable', true)

      return new Promise((resolve, reject) => {

        let uri = 'exchante-return-transaction'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }
      
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
            commit('setShowSkeletonTable', false)
          })
      })
    },
    showExchanteReturnTransaction({commit, getters}, id) {

      return new Promise((resolve, reject) => {

        let uri = 'exchante-return-transaction/'+id
        
        axios.get(uri)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err))
          .finally(()=>{
          })
      })
    },
    createExchanteReturnTransaction({ commit, getters }, data){

      return new Promise((resolve, reject) => {
        axios.post('exchante-return-transaction', data)
          .then(res => {
            if(res.data) {

              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
