import Client from '@/services/Client'

const axios = new Client();

export const ProductItem = {
  state: {
    item: {},
    productItems: [],
    loadTableItemProduct: true,
  },
  getters: {
    getProductItems: state => state.productItems,
    getLoadTableItemProduct: state => state.loadTableItemProduct,
    getItemSelected: state => state.item,
  },
  mutations: {
    setProductItems: (state, value) => state.productItems = value,
    setLoadTableItemProduct: (state, value) => state.loadTableItemProduct = value,
    setItemSelected: (state, value) => state.item = value,
  },
  actions: {
    ActionSetItemSelected({commit}, value) {
      commit('setItemSelected', Object.assign({}, value));
    },
    updateItemProduct({commit}, itemProduct) {
      return new Promise((resolve, reject) => {
        axios.put('product-items/' + itemProduct.id, itemProduct)
          .then(res => {
            if (res.data) {
              resolve(res)
              commit('setItemSelected', {});
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createItemProduct({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('product-items', data)
          .then(res => {
            if (res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    listProductItems({commit, getters}, url) {
      commit('setLoadTableItemProduct', true)
      return new Promise((resolve, reject) => {
        commit('setShowSkeletonTable', true)
        let uri = 'product-items';
        if (url) {
          uri = 'product-items'
          let query = new URLSearchParams(url).toString();
          uri += query ? '?' + query : '';
        }

        axios.get(uri)
          .then(res => {
            commit('setProductItems', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(() => {
            commit('setLoadTableItemProduct', false)
            commit('setShowSkeletonTable', false)
          });
      })
    },
  }
}
