import Client from '@/services/Client'
const axios = new Client()

export const integration = {
  state: {
    integrations:{}
  },
  getters: {
    getIntegrations: state => state.integrations
  },
  mutations: {
    setIntegrations:(state, value) => state.integrations = value
  },
  actions: {
    listIntegrations({commit}, url) {

      return new Promise((resolve, reject) => {
        
        let uri = 'integrations'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
        .then(res => {
          commit('setIntegrations', res.data)
          resolve(res.data)
        })
        .catch(err => reject(err))
        .finally(()=>{
        })
      })
    },
    createIntegration({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.post('integrations', data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateIntegration({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('integrations/'+data.id, data)
          .then(res => {
            if(res.data) {
              resolve(res.data)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
