import Client from '@/services/Client'
const axios = new Client()
import VueNotificationsOptions from '../../.././util/notification.js'

export const provider = {
  state: {
    providers: [],
    providerReceivers: [],
  },
  getters: {
    getProvidersData: state => state.providers,
    getProviderReceivers: state => state.providerReceivers,
  },
  mutations: {
    setProviders: (state, value) => state.providers = value,
    setProviderReceivers: (state, value) => state.providerReceivers = value,
  },
  actions: {
    listProviders({ commit }, url) {
      if(url.load !== false){
        commit('setShowSkeletonTable', true)
      }

      return new Promise((resolve, reject) => {


        axios.get(url.url)
          .then(res => {
            commit('setProviders', res.data)
            resolve(res)
          })
          .catch(err => reject(err))
          .finally(()=>{
            if(url.load !== false){
              commit('setShowSkeletonTable', false)
            }
          })
      })
    },
    findProvider({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get('provider/'+id)
          .then(res => {
            resolve(res)
          })
          .catch(err => reject(err))
      })
    },
    createProvider({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.post('provider', data)
          .then(res => {
            if(res.data.success) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    updateProvider({ commit }, data){
      return new Promise((resolve, reject) => {
        axios.put('provider/'+data.id, data)
          .then(res => {
            if(res.data.success) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    getProviderReceiver({ commit }, data){

      if(!data.provider_id){
        commit('setProviderReceivers', [])
        return
      }
      return new Promise((resolve, reject) => {
        axios.get('provider-receivers/'+data.provider_id)
          .then(res => {
            if(res.data) {
              commit('setProviderReceivers', res.data)
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    createProviderReceiver({ commit, dispatch, getters }, data){

      if(!data.provider_id){
        let percentageOnline = getters.getProviderReceivers.find(item=>item.type === 'ONLINE_SALE')
        let percentageLocal = getters.getProviderReceivers.find(item=>item.type === 'LOCAL_SALE')
        if(
            percentageOnline && +percentageOnline.percentage + (+data.percentage) > 100 ||
            percentageLocal && +percentageLocal.percentage + (+data.percentage) > 100
        ){
          VueNotificationsOptions.error({
            title: 'Recebedor',
            message:'Valor ultrapassa os 100%',
            type: 'error',
            timeout: 3000,
            cb: undefined
          })
          return false
        }

        if(getters.getProviderReceivers.find(item=>data.type === item.type)){
          VueNotificationsOptions.error({
            title: 'Recebedor',
            message:'Configuração já adicionada!',
            type: 'error',
            timeout: 3000,
            cb: undefined
          })
          return false
        }

        getters.getProviderReceivers.push(data)

        commit('setProviderReceivers', getters.getProviderReceivers)
        return true
      }

      return new Promise((resolve, reject) => {
        axios.post('provider-receivers', data)
          .then(res => {
            if(res.data) {
              dispatch('getProviderReceiver', data)
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
    removeProviderReceiver({ commit, dispatch, getters }, id){

      if(!id.id){
        let data = getters.getProviderReceivers.map(item=>{
          if(item.type != id.type){
            return item
          }
        }).filter(item => item)

        commit('setProviderReceivers',data)
        return true
      }

      return new Promise((resolve, reject) => {
        axios.delete('provider-receivers/'+id.id)
          .then(res => {
            if(res.data) {
              dispatch('getProviderReceiver', {provider_id: id.provider_id})
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    },
  }
}
