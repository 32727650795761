import Client from '@/services/Client'
const axios = new Client()

export const freight = {
  state: {
    freights: [],
    statusFreight:{
      'OFF':'Inativo',
      'ADMIN': 'Administrador',
      'SELLER': 'vendedor',
    }
  },
  getters: {
    getFreights: state => state.freights,
    getStatusFreight: state => state.statusFreight,
  },
  mutations: {
    setFreights: (state, value) => state.freights = value,
  },
  actions: {
    listFreights({commit}, url) {
      commit('setShowSkeletonTable', true)

      return new Promise((resolve, reject) => {
        
        let uri = 'freight'
        if(url){
          let query = new URLSearchParams(url).toString();
          uri += query ? '?'+query : '';
        }

        axios.get(uri)
        .then(res => {
          commit('setFreights', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
        .finally(()=>{
          commit('setShowSkeletonTable', false)
        })
      })
    },
    updateFreight({commit}, data) {
      return new Promise((resolve, reject) => {
        axios.put('user/'+data.user.id, data)
          .then(res => {
            if(res.data) {
              resolve(res)
            } else reject(res)
          })
          .catch(err => reject(err))
      })
    }
  }
}
