<template>
  <div
    class="main-content bg-default content-auth"
    style="overflow-y: auto; overflow-x: hidden"
  >
    <!-- <base-nav
      v-model="showMenu"
      :transparent="true"
      menu-classes="justify-content-end"
      class="navbar-horizontal navbar-main navbar-top navbar-dark"
      expand="lg"
    > -->
      <!-- <div slot="brand" class="navbar-wrapper">
        <b-navbar-brand to="/"> 
          <b-img fluid src="../../img/brand/newLogo2.png" alt="..." width="190px" />
       </b-navbar-brand>
      </div> -->

      <!-- <template>
       <div class="navbar-collapse-header">
          <b-row>
           <b-col cols="6" class="collapse-brand">
            
           </b-col>
            <b-col cols="6" class="collapse-close">
              <button type="button" class="navbar-toggler" @click="showMenu = false">
                <span></span>
                <span></span>
              </button>
            </b-col>
          </b-row>
        </div>
         <b-navbar-nav  class="align-items-lg-center ml-lg-auto">
          <b-nav-item to="/register">
            <i class="ni ni-circle-08"></i>
               <span class="nav-link-inner--text">Register</span>
          </b-nav-item>
           <b-nav-item to="/login">
            <i class="ni ni-key-25"></i>
            <span class="nav-link-inner--text">Login</span>
          </b-nav-item>
        </b-navbar-nav>
      </template> 
    </base-nav>-->

    <div class="main-content">
      <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
        <router-view></router-view>
      </zoom-center-transition>
    </div>

    <!-- <footer class="py-5" id="footer-main">
      <b-container >
        <b-row align-v="center" class="justify-content-xl-between">
          <b-col xl="6">
            <div class="copyright text-center text-xl-left text-muted">
              © {{year}} <a href="https://www.evolveresoftware.com" class="font-weight-bold ml-1" target="_blank">Evolvere Software</a>
            </div>
          </b-col>
        </b-row>
      </b-container> -->
    </footer>
  </div>
</template>
<script>
import { BaseNav } from "@/components";
import { ZoomCenterTransition } from "vue2-transitions";

export default {
  components: {
    BaseNav,
    ZoomCenterTransition,
  },
  props: {
    backgroundColor: {
      type: String,
      default: "black",
    },
  },
  data() {
    return {
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: "login-page",
    };
  },
  computed: {
    title() {
      return `${this.$route.name} Page`;
    },
  },
  methods: {
    toggleNavbar() {
      document.body.classList.toggle("nav-open");
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove("nav-open");
      this.showMenu = false;
    },
    setBackgroundColor() {
      document.body.classList.add("bg-default");
    },
    removeBackgroundColor() {
      document.body.classList.remove("bg-default");
    },
    updateBackground() {
      if (!this.$route.meta.noBodyBackground) {
        this.setBackgroundColor();
      } else {
        this.removeBackgroundColor();
      }
    },
  },
  beforeDestroy() {
    this.removeBackgroundColor();
  },
  beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function () {
        this.updateBackground();
      },
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}

.main-content .zoomIn {
  animation-name: zoomIn8;
}

@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-content .zoomOut {
  animation-name: zoomOut8;
}
</style>

<style>
.content-auth {
  background-image: url("@/assets/img/page/login.svg");
  z-index: 99;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}
</style>
